import "./App.css";
import { Routes, Route } from "react-router-dom";
import PortalWrapper from "./pages/PortalWrapper";
import PortalLogin from "./pages/PortalLogin";
import Dashboard from "./pages/setup/Dashboard";
import SchoolFees from "./pages/setup/SchoolFees";
import PaymentVerification from "./pages/verification";
import SuccessPayment from "./components/SuccessPayment";
import CourseRegistration from "./pages/setup/CourseRegistration";
import Complaint from "./pages/setup/Complaint";
import Accomodation from "./pages/setup/Accomodation";
import { ToastContainer } from "react-toastify";
import Library from "./pages/setup/Library";
import SchoolFeeInvoice from "./pages/SchoolFeeInvoice";
import HostelInvoice from "./pages/HostelInvoice";
import UpdateBioData from "./pages/setup/UpdateBioData";
import AllPayments from "./pages/setup/invoice/AllPayments";
import ResetPassword from "./pages/ResetPassword";
import NewPassword from "./pages/NewPassword";
import CourseRegPrintout from "./pages/setup/Print/CourseRegPrintout";
import CourseHistory from "./pages/setup/CourseHistory";
import CourseModification from "./pages/setup/CourseModification";
import CourseRegDetails from "./pages/setup/CourseRegDetails";
import CourseMapping from "./pages/setup/CourseMapping";
import CourseMappingModification from "./pages/setup/CourseMappingModification";
import Courses from "./pages/setup/Courses";
import PGCourses from "./pages/setup/PG/PGCourses";
import PGCourseModification from "./pages/setup/PG/PGCourseModification";
import PGCourseRegDetails from "./pages/setup/PG/PGCourseRegDetails";
import PGCourseMapping from "./pages/setup/PG/PGCourseMapping";
import PGCourseMappingModification from "./pages/setup/PG/PGCourseMappingModification";

function App() {
  return (
    <div className="App">
      <ToastContainer />
      <Routes>
        <Route
          exact
          path="/invoice/school-fee"
          element={<SchoolFeeInvoice />}
        />
        <Route exact path="/invoice/hostel" element={<HostelInvoice />} />
        {/*Verification*/}
        <Route
          exact
          path="/paystack/verify"
          element={<PaymentVerification />}
        />
        {/*success*/}
        <Route exact path="/successful" element={<SuccessPayment />} />

        {/*Reset password*/}
        <Route exact path="/reset-password" element={<ResetPassword />} />
        <Route
          exact
          path="/password/reset/:uidb64/:token"
          element={<NewPassword />}
        />

        <Route exact path="/login" element={<PortalLogin />} />
        <Route exact path="/" element={<PortalWrapper />}>
          <Route exact path="" element={<Dashboard />} />
          {/* <Route exact path='/payment' element={<SchoolFees/>}/>
          <Route exact path='/course-reg' element={<CourseRegistration/>}/>
          <Route exact path='complaint' element={<Complaint/>}/>
          <Route exact path='/accomodation' element={<Accomodation/>}/>
          <Route exact path='library' element={<Library/>}/>
          <Route exact path='/biodata' element={<UpdateBioData/>}/>
          <Route exact path='/history/payments' element = {<AllPayments/>}/>
          <Route exact path='/course-reg/print' element={<CourseRegPrintout/>}/>
          <Route exact path='/course-reg/history' element={<CourseHistory/>}/> */}

          {/**Admin course */}
          <Route
            exact
            path="/course-modification"
            element={<CourseModification />}
          />
          <Route
            exact
            path="/courses"
            element={<Courses />}
          />
          <Route
            exact
            path="/course-modification/info"
            element={<CourseRegDetails />}
          />
          <Route exact path="/course-mapping" element={<CourseMapping />} />
          <Route
            exact
            path="/course-mapping/modification"
            element={<CourseMappingModification />}
          />
          {/*PG */}
          <Route
            exact
            path="/pg_courses"
            element={<PGCourses />}
          />
          <Route
            exact
            path="/pg_course-modification"
            element={<PGCourseModification />}
          />
          <Route
            exact
            path="/pg_course-modification/info"
            element={<PGCourseRegDetails />}
          />
          <Route exact path="/pg_course-mapping" element={<PGCourseMapping />} />
          <Route
            exact
            path="/pg_course-mapping/modification"
            element={<PGCourseMappingModification />}
          />
        </Route>
      </Routes>
    </div>
  );
}

export default App;
