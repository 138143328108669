import { useEffect, useState } from "react";
import { DataTable } from "primereact/datatable";
import { InputText } from "primereact/inputtext";
import { FilterMatchMode, FilterOperator } from "primereact/api";
import { useLocation, useNavigate } from "react-router-dom";
import { Column } from "primereact/column";
import { Paginator } from "primereact/paginator";
import { Trash2, Edit } from "react-feather";
import { toast } from "react-toastify";
import apiPG from "../../../api/apiPG";
import CourseModal from "../../../components/CourseModal";
import Footer from "../../../components/Footer";
import PGCourseModal from "../../../components/PGCourseModal";

const PGCourses = () => {
  const [level, setLevel] = useState("");
  const [matNo, setMatNo] = useState("");
  const [semester, setSemester] = useState("");
  const [pagination, setPagination] = useState(0);
  const [pageSize, setPageSize] = useState(50);
  const [totalRecords, setTotalRecords] = useState();
  const [courses, setCourses] = useState([]);
  const [loading, setLoading] = useState(false);
  const [updateData, setUpdateData] = useState();

  const [show, setShow] = useState(false);

  const handleClose = () => {
    setUpdateData();
    setShow(false);
  };
  const handleShow = () => {
    setShow(true);
  };

  const [filters, setFilters] = useState({
    global: { value: null, matchMode: FilterMatchMode.CONTAINS },
    surname: {
      operator: FilterOperator.AND,
      constraints: [{ value: null, matchMode: FilterMatchMode.STARTS_WITH }],
    },
  });
  const [globalFilterValue, setGlobalFilterValue] = useState("");

  const navigate = useNavigate();
  const location = useLocation();

  //   const token = user?.access;

  const onPageChange = (event) => {
    console.log(event, "<<<<<<<");
    setPagination(event.page); // API pagination typically starts at 1
  };

  const onRowsChange = (event) => {
    setPagination(1); // Reset to the first page when changing page size
    setPageSize(event.value);
  };

  const getCourses = async () => {
    // if (!level && !matNo) return;
    try {
      setLoading(true);
      const { data } = await apiPG.get(
        `/courses/all/?p=${
          pagination + 1
        }&level=${level}&semester_int=${semester}`
      );
      console.log(data, "courses");
      setTotalRecords(data.count);
      setCourses(data.data);
      setLoading(false);
    } catch (error) {
      console.log(error);
      setLoading(false);
    }
  };

  const handleEdit = (data) => {
    setUpdateData(data);
    console.log(data, "edit");
    setShow(true);
  };

  const handleDelete = async (id) => {
    setLoading(true);
    try {
      const confirmDelete = window.confirm(
        "Are you sure you want to delete this course?"
      );

      if (confirmDelete) {
        await apiPG.delete(`/courses/staff/delete/${id}`);
        toast.success("Course deleted");
        getCourses();
        setLoading(false);
      }
    } catch (error) {
      setLoading(false);
    } finally {
      setLoading(false);
    }
  };

  const onGlobalFilterChange = (e) => {
    const value = e.target.value;
    let _filters = { ...filters };
    console.log(_filters, "_filterrr");

    _filters["global"].value = value;

    setFilters(_filters);
    setGlobalFilterValue(value);
  };

  const renderHeader = () => {
    return (
      <div className="flex justify-content-end">
        <span className="p-input-icon-left">
          <i className="pi pi-search" />
          <InputText
            value={globalFilterValue}
            onChange={onGlobalFilterChange}
            placeholder="Keyword Search"
          />
        </span>
      </div>
    );
  };

  const actions = (data) => {
    return (
      <div>
        <Edit
          className="waves-effect align-self-center icon-dual-primary icon-md mr-2"
          onClick={() => handleEdit(data)}
        />
        <Trash2
          className="waves-effect align-self-center icon-dual-pink icon-md"
          onClick={() => handleDelete(data.id)}
        />
      </div>
    );
  };

  const header = renderHeader();

  useEffect(() => {
    getCourses();
  }, [pagination, level, semester]);
  return (
    <div className="page-wrapper">
      <div class="page-content-tab">
        <div class="container-fluid">
          <div class="row">
            <div class="col-sm-12">
              <div class="page-title-box">
                <div class="float-right">
                  <ol class="breadcrumb">
                    {/* <li class="breadcrumb-item"><a href="javascript:void(0);">Metrica</a></li>
                            <li class="breadcrumb-item"><a href="javascript:void(0);">Pages</a></li> */}
                    <li class="breadcrumb-item active">Registration</li>
                  </ol>
                </div>
                <h4 class="page-title">View PG courses</h4>
              </div>
            </div>
          </div>
          <div class="row">
            <div class="col-12">
              <div class="tab-content detail-list" id="pills-tabContent">
                <div class="tab-pane fade show active" id="general_detail">
                  <div class="row">
                    <div class="col-lg-12 col-xl-12 mx-auto">
                      <div class="card">
                        <div class="card-body">
                          <div class="container">
                            <h5 className="mb-3">Filter options</h5>
                            <div class="form-group row">
                              <div class="col-md-3">
                                <label>Level</label>
                                <select
                                  class="form-control mb-3"
                                  value={level}
                                  onChange={(e) => setLevel(e.target.value)}
                                >
                                  <option></option>
                                  <option>700</option>
                                  <option>800</option>
                                </select>
                              </div>
                              <div class="col-md-3">
                                <label>Enter semester</label>
                                <select
                                  class="form-control mb-3"
                                  value={semester}
                                  onChange={(e) => setSemester(e.target.value)}
                                >
                                  <option value=""></option>
                                  <option value="1">First</option>
                                  <option value="2">Second</option>
                                </select>
                              </div>
                            </div>
                            <div className="my-3">
                              <button
                                className="btn btn-primary waves-effect"
                                onClick={handleShow}
                              >
                                Add Course
                              </button>
                            </div>

                            <div className="col-lg-12">
                              <DataTable
                                value={courses}
                                dataKey="id"
                                tableStyle={{ minWidth: "50rem" }}
                                loading={loading}
                                // header={header}
                                filters={filters}
                                size="small"
                              >
                                <Column
                                  field="code"
                                  header="Course Code"
                                ></Column>
                                <Column
                                  field="title"
                                  header="Course Title"
                                ></Column>
                                <Column
                                  field="unit"
                                  header="Course Unit"
                                ></Column>
                                <Column
                                  field="semester"
                                  header="Semester"
                                ></Column>
                                <Column
                                  header="Actions"
                                  body={actions}
                                ></Column>
                              </DataTable>
                              <Paginator
                                first={pagination}
                                rows={1}
                                totalRecords={totalRecords}
                                onPageChange={onPageChange}
                                onRowsChange={onRowsChange}
                                template=" PrevPageLink CurrentPageReport NextPageLink"
                              />
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <Footer />
      </div>

      {/*modal */}
      <PGCourseModal
        handleClose={handleClose}
        show={show}
        {...updateData}
        getCourses={getCourses}
      />
    </div>
  );
};

export default PGCourses;
